
import * as actionTypes from "./actionTypes"

const initialState: TransactionState = {
  transactions: [],
}

const reducer = (
    state: TransactionState = initialState,
    action: TransactionAction
  ): TransactionState => {
    switch (action.type) {
      case actionTypes.ADD_TRANSACTION:
          
        const newTransaction: ITransaction = {
            signature: "yojustisgned",
            commitment: "unknown",
            time: Date.now()
        }
        return {
          ...state,
          transactions: state.transactions.concat(newTransaction),
        }
      case actionTypes.CONFIRM_TRANSACTION:
        const updatedTransactions: ITransaction[] = state.transactions.filter(
          tx => tx.signature !== action.transaction.signature
        )
        return {
          ...state,
          transactions: updatedTransactions,
        }
    }
    return state
  }
  
  export default reducer
  