import * as anchor from '@project-serum/anchor';
import { BN, Idl, Program, Provider } from '@project-serum/anchor';
import { Connection, Keypair, PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY } from '@solana/web3.js';
import {
  AccountInfo,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { GemBank } from './gem_bank';
import {
  findGdrPDA,
  findGemBoxPDA,
  findRarityPDA,
  findRewardPDA,
  findVaultAuthorityPDA,
  findBankAuthorityPDA,
  findAuthorizationProofPDA,
  findVaultPDA,
  findWhitelistProofPDA,
} from './gem-bank.pda';
import { AccountUtils } from './account-utils';
import { isKp } from './types';

export enum BankFlags {
  FreezeVaults = 1 << 0,
}

export enum WhitelistType {
  Creator = 1 << 0,
  Mint = 1 << 1,
}

export class GemBankClient extends AccountUtils {
  // @ts-ignore
  wallet: Wallet;
  provider!: Provider;
  bankProgram!: anchor.Program<GemBank>;

  constructor(
    conn: Connection,
    // @ts-ignore
    wallet: Wallet,
    idl?: Idl,
    programId?: PublicKey
  ) {
    super(conn);
    this.wallet = wallet;
    this.setProvider();
    this.setBankProgram(idl, programId);
  }

  setProvider() {
    this.provider = new anchor.AnchorProvider(
      this.conn,
      this.wallet,
      anchor.AnchorProvider.defaultOptions()
    );
    anchor.setProvider(this.provider);
  }

  setBankProgram(idl?: Idl, programId?: PublicKey) {
    //instantiating program depends on the environment
    if (idl && programId) {
      //means running in prod
      this.bankProgram = new anchor.Program<GemBank>(
        idl as any,
        programId,
        this.provider
      );
    } 
  }

  // --------------------------------------- fetch deserialized accounts

  async fetchBankAcc(bank: PublicKey) {
    return this.bankProgram.account.bank.fetch(bank);
  }

  async fetchVaultAcc(vault: PublicKey) {
    return this.bankProgram.account.vault.fetch(vault);
  }

  async fetchGDRAcc(GDR: PublicKey) {
    return this.bankProgram.account.gemDepositReceipt.fetch(GDR);
  }

  async fetchGemAcc(mint: PublicKey, gemAcc: PublicKey): Promise<AccountInfo> {
    return this.deserializeTokenAccount(mint, gemAcc);
  }

  async fetchWhitelistProofAcc(proof: PublicKey) {
    return this.bankProgram.account.whitelistProof.fetch(proof);
  }

  async fetchRarity(rarity: PublicKey) {
    return this.bankProgram.account.rarity.fetch(rarity);
  }

  // --------------------------------------- get all PDAs by type
  //https://project-serum.github.io/anchor/ts/classes/accountclient.html#all

  async fetchAllBankPDAs(manager?: PublicKey) {
    const filter = manager
      ? [
          {
            memcmp: {
              offset: 10, //need to prepend 8 bytes for anchor's disc
              bytes: manager.toBase58(),
            },
          },
        ]
      : [];
    const pdas = await this.bankProgram.account.bank.all(filter);
    //console.log(`found a total of ${pdas.length} bank PDAs`);
    return pdas;
  }

  async fetchAllVaultPDAs(bank?: PublicKey) {
    const filter = bank
      ? [
          {
            memcmp: {
              offset: 8, //need to prepend 8 bytes for anchor's disc
              bytes: bank.toBase58(),
            },
          },
        ]
      : [];
    const pdas = await this.bankProgram.account.vault.all(filter);
    //console.log(`found a total of ${pdas.length} vault PDAs`);
    return pdas;
  }

  async fetchAllGdrPDAs(vault?: PublicKey) {
    const filter = vault
      ? [
          {
            memcmp: {
              offset: 8, //need to prepend 8 bytes for anchor's disc
              bytes: vault.toBase58(),
            },
          },
        ]
      : [];
    const pdas = await this.bankProgram.account.gemDepositReceipt.all(filter);
    //console.log(`found a total of ${pdas.length} GDR PDAs`);
    return pdas;
  }

  async fetchAllWhitelistProofPDAs(bank?: PublicKey) {
    const filter = bank
      ? [
          {
            memcmp: {
              offset: 41, //need to prepend 8 bytes for anchor's disc
              bytes: bank.toBase58(),
            },
          },
        ]
      : [];
    const pdas = await this.bankProgram.account.whitelistProof.all(filter);
    //console.log(`found a total of ${pdas.length} whitelist proofs`);
    return pdas;
  }

  async fetchAllRarityPDAs() {
    //todo need to add client-side (not stored in PDA) filtering based on finding PDAs for given bank and mint
    const pdas = await this.bankProgram.account.rarity.all();
    //console.log(`found a total of ${pdas.length} rarity PDAs`);
    return pdas;
  }

  // --------------------------------------- execute ixs

  async initBank(
    bank: Keypair,
    bankManager: PublicKey | Keypair,
    rewardMint: PublicKey | Keypair,
    payer: PublicKey | Keypair,
    rate: number = 15000,
    denominator: number = 86400

  ) {
    const signers = [bank];
    if (isKp(bankManager)) signers.push(<Keypair>bankManager);
    const [bankAuth, bankAuthBump] = await findBankAuthorityPDA(bank.publicKey);
    const rewardMintKey = isKp(rewardMint) ? (<Keypair>rewardMint).publicKey : <PublicKey>rewardMint;
    const [rewardPot, rewardPotBump] = await findRewardPDA(
      bank.publicKey,
      rewardMintKey
    );
    //console.log('starting bank at', bank.publicKey.toBase58());
    //console.log(`bankauth and bump and seed ${bankAuth} ${bankAuthBump} ${bank.publicKey.toBase58()}`);
    const txSig = await this.bankProgram.rpc.initBank(new BN(denominator), new BN(rate), bankAuthBump,{
      accounts: {
        bank: bank.publicKey,
        bankManager: isKp(bankManager)
          ? (<Keypair>bankManager).publicKey
          : bankManager,
        bankAuthority: bankAuth,
        rewardMint: rewardMintKey,
        rewardPool: rewardPot,
        payer: isKp(payer) ? (<Keypair>payer).publicKey : payer,
        rent: SYSVAR_RENT_PUBKEY,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
      },
      signers,
    });

    return { txSig };
  }

  async fundReward(
    bank: PublicKey,
    rewardMint: PublicKey,
    funder: PublicKey | Keypair,
    rewardSource: PublicKey,
    amount: number
  ) {
     const funderPk = isKp(funder)
       ? (<Keypair>funder).publicKey
       : <PublicKey>funder;

     const [bankAuth, bankAuthBump] = await findBankAuthorityPDA(bank);
     const [authorizationProof, authorizationProofBump] =
       await findAuthorizationProofPDA(bank, funderPk);
     const [pot, potBump] = await findRewardPDA(bank, rewardMint);

     const signers = [];
     if (isKp(funder)) signers.push(<Keypair>funder);

    //console.log('funding reward pot', pot.toBase58());
    //console.log('funding bank', bank.toBase58());
     const txSig = await this.bankProgram.rpc.fundReward(
       authorizationProofBump,
       potBump,
       new BN(amount),
       {
         accounts: {
           bank,
           authorizationProof,
           authorizedFunder: funderPk,
           rewardPot: pot,
           rewardSource,
           rewardMint,
           tokenProgram: TOKEN_PROGRAM_ID,
           systemProgram: SystemProgram.programId,
         },
         signers,
       }
     );
     
     return {
       authorizationProof,
       authorizationProofBump,
       pot,
       potBump,
       txSig,
     };
  }

  async updateBankManager(
    bank: PublicKey,
    bankManager: PublicKey | Keypair,
    newManager: PublicKey
  ) {
    const signers = [];
    if (isKp(bankManager)) signers.push(<Keypair>bankManager);

    //console.log('updating bank manager to', newManager.toBase58());
    const txSig = await this.bankProgram.rpc.updateBankManager(newManager, {
      accounts: {
        bank,
        bankManager: isKp(bankManager)
          ? (<Keypair>bankManager).publicKey
          : bankManager,
      },
      signers,
    });

    return { txSig };
  }

  async initVault(
    bank: PublicKey,
    creator: PublicKey | Keypair,
    payer: PublicKey | Keypair,
    owner: PublicKey,
    name: string
  ) {
    const creatorPk = isKp(creator)
      ? (<Keypair>creator).publicKey
      : <PublicKey>creator;

    const [vault, vaultBump] = await findVaultPDA(bank, creatorPk);
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault); //nice-to-have

    const signers = [];
    if (isKp(creator)) signers.push(<Keypair>creator);
    if (isKp(payer)) signers.push(<Keypair>payer);

    //console.log('creating vault at', vault.toBase58());
    const txSig = await this.bankProgram.rpc.initVault(owner, name, {
      accounts: {
        bank,
        vault,
        creator: creatorPk,
        payer: isKp(payer) ? (<Keypair>payer).publicKey : <PublicKey>payer,
        systemProgram: SystemProgram.programId,
      },
      signers,
    });

    return { vault, vaultBump, vaultAuth, vaultAuthBump, txSig };
  }

  async updateVaultOwner(
    bank: PublicKey,
    vault: PublicKey,
    existingOwner: Keypair | PublicKey,
    newOwner: PublicKey
  ) {
    const signers = [];
    if (isKp(existingOwner)) signers.push(<Keypair>existingOwner);

    //console.log('updating vault owner to', newOwner.toBase58());
    const txSig = await this.bankProgram.rpc.updateVaultOwner(newOwner, {
      accounts: {
        bank,
        vault,
        owner: isKp(existingOwner)
          ? (<Keypair>existingOwner).publicKey
          : existingOwner,
      },
      signers,
    });

    return { txSig };
  }

  async setVaultLock(
    bank: PublicKey,
    vault: PublicKey,
    bankManager: PublicKey | Keypair,
    vaultLocked: boolean
  ) {
    const signers = [];
    if (isKp(bankManager)) signers.push(<Keypair>bankManager);

    //console.log('setting vault lock to', vaultLocked);
    const txSig = await this.bankProgram.rpc.setVaultLock(vaultLocked, {
      accounts: {
        bank,
        vault,
        bankManager: isKp(bankManager)
          ? (<Keypair>bankManager).publicKey
          : bankManager,
      },
      signers,
    });

    return { txSig };
  }

  async setBankFlags(
    bank: PublicKey,
    bankManager: PublicKey | Keypair,
    flags: BankFlags
  ) {
    const signers = [];
    if (isKp(bankManager)) signers.push(<Keypair>bankManager);

    //console.log('setting bank flags to', flags);
    const txSig = await this.bankProgram.rpc.setBankFlags(flags, {
      accounts: {
        bank,
        bankManager: bankManager
          ? (<Keypair>bankManager).publicKey
          : bankManager,
      },
      signers,
    });

    return { txSig };
  }

  async depositGem(
    bank: PublicKey,
    vault: PublicKey,
    vaultOwner: PublicKey | Keypair,
    gemAmount: BN,
    gemMint: PublicKey,
    gemSource: PublicKey,
    mintProof?: PublicKey,
    metadata?: PublicKey,
    creatorProof?: PublicKey
  ) {
    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint);
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint);
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault);
    const [gemRarity, gemRarityBump] = await findRarityPDA(bank, gemMint);

    const remainingAccounts = [];
    if (mintProof)
      remainingAccounts.push({
        pubkey: mintProof,
        isWritable: false,
        isSigner: false,
      });
    if (metadata)
      remainingAccounts.push({
        pubkey: metadata,
        isWritable: false,
        isSigner: false,
      });
    if (creatorProof)
      remainingAccounts.push({
        pubkey: creatorProof,
        isWritable: false,
        isSigner: false,
      });

    const signers = [];
    if (isKp(vaultOwner)) signers.push(<Keypair>vaultOwner);

    ////console.log(
    //  `depositing ${gemAmount} gems into ${gemBox.toBase58()}, GDR ${GDR.toBase58()}`
    //);
    const txSig = await this.bankProgram.rpc.depositGem(
      vaultAuthBump,
      gemRarityBump,
      gemAmount,
      {
        accounts: {
          bank,
          vault,
          owner: isKp(vaultOwner)
            ? (<Keypair>vaultOwner).publicKey
            : vaultOwner,
          authority: vaultAuth,
          gemBox,
          gemDepositReceipt: GDR,
          gemSource,
          gemMint,
          gemRarity,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        },
        remainingAccounts,
        signers,
      }
    );

    return {
      vaultAuth,
      vaultAuthBump,
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      txSig,
    };
  }

  async depositGemWithFee(
    bank: PublicKey,
    vault: PublicKey,
    vaultOwner: PublicKey | Keypair,
    gemAmount: BN,
    gemMint: PublicKey,
    gemSource: PublicKey,
    feeAccount: PublicKey,
    rewardMint: PublicKey,
    mintProof?: PublicKey,
    metadata?: PublicKey,
    creatorProof?: PublicKey
  ) {
    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint);
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint);
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault);
    const [gemRarity, gemRarityBump] = await findRarityPDA(bank, gemMint);

    const remainingAccounts = [];
    if (mintProof)
      remainingAccounts.push({
        pubkey: mintProof,
        isWritable: false,
        isSigner: false,
      });
    if (metadata)
      remainingAccounts.push({
        pubkey: metadata,
        isWritable: false,
        isSigner: false,
      });
    if (creatorProof)
      remainingAccounts.push({
        pubkey: creatorProof,
        isWritable: false,
        isSigner: false,
      });

    const signers = [];
    if (isKp(vaultOwner)) signers.push(<Keypair>vaultOwner);

    ////console.log(
    //  `depositing ${gemAmount} gems into ${gemBox.toBase58()}, GDR ${GDR.toBase58()}`
    //);
    const txSig = await this.bankProgram.rpc.depositGemWithFee(
      vaultAuthBump,
      gemRarityBump,
      gemAmount,
      {
        accounts: {
          bank,
          vault,
          owner: isKp(vaultOwner)
            ? (<Keypair>vaultOwner).publicKey
            : vaultOwner,
          authority: vaultAuth,
          gemBox,
          gemDepositReceipt: GDR,
          gemSource,
          gemMint,
          gemRarity,
          feeAccount,
          rewardMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        },
        remainingAccounts,
        signers,
      }
    );

    return {
      vaultAuth,
      vaultAuthBump,
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      txSig,
    };
  }

  async withdrawGem(
    bank: PublicKey,
    vault: PublicKey,
    vaultOwner: PublicKey | Keypair,
    gemAmount: BN,
    gemMint: PublicKey,
    receiver: PublicKey,
  ) {
    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint);
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint);
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault);
    const [gemRarity, gemRarityBump] = await findRarityPDA(bank, gemMint);

    const gemDestination = await this.findATA(gemMint, receiver);

    const signers = [];
    if (isKp(vaultOwner)) signers.push(<Keypair>vaultOwner);

    ////console.log(
    //  `withdrawing ${gemAmount} gems from ${gemBox.toBase58()}, GDR ${GDR.toBase58()}`
    //);
    const txSig = await this.bankProgram.rpc.withdrawGem(
      vaultAuthBump,
      gemBoxBump,
      GDRBump,
      gemRarityBump,
      gemAmount,
      {
        accounts: {
          bank,
          vault,
          owner: isKp(vaultOwner)
            ? (<Keypair>vaultOwner).publicKey
            : vaultOwner,
          authority: vaultAuth,
          gemBox,
          gemDepositReceipt: GDR,
          gemDestination,
          gemMint,
          gemRarity,
          receiver,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        },
        signers,
      }
    );

    return {
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      vaultAuth,
      vaultAuthBump,
      gemDestination,
      txSig,
    };
  }

  async withdrawGemWithFee(
    bank: PublicKey,
    vault: PublicKey,
    vaultOwner: PublicKey | Keypair,
    gemAmount: BN,
    gemMint: PublicKey,
    receiver: PublicKey,
    feeAccount: PublicKey,
    rewardMint: PublicKey
  ) {
    const [gemBox, gemBoxBump] = await findGemBoxPDA(vault, gemMint);
    const [GDR, GDRBump] = await findGdrPDA(vault, gemMint);
    const [vaultAuth, vaultAuthBump] = await findVaultAuthorityPDA(vault);
    const [gemRarity, gemRarityBump] = await findRarityPDA(bank, gemMint);

    const gemDestination = await this.findATA(gemMint, receiver);

    const signers = [];
    if (isKp(vaultOwner)) signers.push(<Keypair>vaultOwner);

    ////console.log(
    //  `withdrawing ${gemAmount} gems from ${gemBox.toBase58()}, GDR ${GDR.toBase58()}`
    //);
    const txSig = await this.bankProgram.rpc.withdrawGemWithFee(
      vaultAuthBump,
      gemBoxBump,
      GDRBump,
      gemRarityBump,
      gemAmount,
      {
        accounts: {
          bank,
          vault,
          owner: isKp(vaultOwner)
            ? (<Keypair>vaultOwner).publicKey
            : vaultOwner,
          authority: vaultAuth,
          gemBox,
          gemDepositReceipt: GDR,
          gemDestination,
          gemMint,
          gemRarity,
          receiver,
          feeAccount,
          rewardMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        },
        signers,
      }
    );

    return {
      gemBox,
      gemBoxBump,
      GDR,
      GDRBump,
      gemRarity,
      gemRarityBump,
      vaultAuth,
      vaultAuthBump,
      gemDestination,
      txSig,
    };
  }

  async withdrawReward(
    bank: PublicKey,
    vault: PublicKey,
    vaultOwner: PublicKey | Keypair,
    rewardMint: PublicKey,
    receiver: PublicKey
  ) {
    const [rewardPool, rewardPoolBump] = await findRewardPDA(bank, rewardMint);

    const [bankAuth, bankAuthBump] = await findBankAuthorityPDA(bank);

    const rewardDestination = await this.findATA(rewardMint, receiver);

    const signers = [];
    if (isKp(vaultOwner)) signers.push(<Keypair>vaultOwner);

    ////console.log(
    //  `withdrawing rewards from ${rewardPool.toBase58()}}`
    //);
    const txSig = await this.bankProgram.rpc.claimReward(
      bankAuthBump,
      rewardPoolBump,
      {
        accounts: {
          bank,
          vault,
          owner: isKp(vaultOwner)
            ? (<Keypair>vaultOwner).publicKey
            : vaultOwner,
          bankAuthority: bankAuth,
          rewardPool,
          rewardDestination,
          rewardMint,
          receiver,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        },
        signers,
      }
    );

    return {
      rewardPool,
      rewardPoolBump,
      bankAuth,
      bankAuthBump,
      rewardDestination,
      txSig,
    };
  }

  async cancelReward(
    bank: PublicKey,
    bankManager: PublicKey | Keypair,
    rewardMint: PublicKey,
    receiver: PublicKey
  ) {
    const [bankAuth, bankAuthBump] = await findBankAuthorityPDA(bank);
    const [pot, potBump] = await findRewardPDA(bank, rewardMint);
    const rewardDestination = await this.findATA(rewardMint, receiver);

    const signers = [];
    if (isKp(bankManager)) signers.push(<Keypair>bankManager);

    const txSig = await this.bankProgram.rpc.cancelReward(
      bankAuthBump,
      potBump,
      {
        accounts: {
          bank,
          bankManager: isKp(bankManager)
            ? (<Keypair>bankManager).publicKey
            : bankManager,
          bankAuthority: bankAuth,
          rewardPool: pot,
          rewardDestination,
          rewardMint,
          receiver,
          tokenProgram: TOKEN_PROGRAM_ID,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: anchor.web3.SYSVAR_RENT_PUBKEY,
        },
        signers,
      }
    );

    return {
      bankAuth,
      bankAuthBump,
      pot,
      potBump,
      rewardDestination,
      txSig,
    };
  }

  async addToWhitelist(
    bank: PublicKey,
    bankManager: PublicKey | Keypair,
    addressToWhitelist: PublicKey,
    whitelistType: WhitelistType,
    payer?: PublicKey
  ) {
    const managerPk = isKp(bankManager)
      ? (<Keypair>bankManager).publicKey
      : <PublicKey>bankManager;

    const [whitelistProof, whitelistBump] = await findWhitelistProofPDA(
      bank,
      addressToWhitelist
    );

    const signers = [];
    if (isKp(bankManager)) signers.push(<Keypair>bankManager);

    const txSig = await this.bankProgram.rpc.addToWhitelist(whitelistType, {
      accounts: {
        bank,
        bankManager: managerPk,
        addressToWhitelist,
        whitelistProof,
        systemProgram: SystemProgram.programId,
        payer: payer ?? managerPk,
      },
      signers,
    });

    return { whitelistProof, whitelistBump, txSig };
  }

  async removeFromWhitelist(
    bank: PublicKey,
    bankManager: PublicKey | Keypair,
    addressToRemove: PublicKey,
    fundsReceiver?: PublicKey
  ) {
    const [whitelistProof, whitelistBump] = await findWhitelistProofPDA(
      bank,
      addressToRemove
    );

    const signers = [];
    if (isKp(bankManager)) signers.push(<Keypair>bankManager);

    const bankManagerPk = isKp(bankManager)
      ? (<Keypair>bankManager).publicKey
      : <PublicKey>bankManager;

    const txSig = await this.bankProgram.rpc.removeFromWhitelist(
      whitelistBump,
      {
        accounts: {
          bank,
          bankManager: bankManagerPk,
          addressToRemove,
          whitelistProof,
          fundsReceiver: fundsReceiver ?? bankManagerPk,
        },
        signers,
      }
    );

    return { whitelistProof, whitelistBump, txSig };
  }

  // --------------------------------------- funder ops ixs

  async authorizeCommon(
    bank: PublicKey,
    bankManager: PublicKey | Keypair,
    funder: PublicKey
  ) {
    const [authorizationProof, authorizationProofBump] =
      await findAuthorizationProofPDA(bank, funder);

    const signers = [];
    if (isKp(bankManager)) signers.push(<Keypair>bankManager);

    let txSig;
    
      //console.log('authorizing funder', funder.toBase58());
      txSig = await this.bankProgram.rpc.authorizeFunder({
        accounts: {
          bank,
          bankManager: isKp(bankManager)
            ? (<Keypair>bankManager).publicKey
            : bankManager,
          funderToAuthorize: funder,
          authorizationProof,
          systemProgram: SystemProgram.programId,
        },
        signers,
      });

    return { authorizationProof, authorizationProofBump, txSig };
  }

  async authorizeFunder(
    bank: PublicKey,
    bankManager: PublicKey | Keypair,
    funderToAuthorize: PublicKey
  ) {
    return this.authorizeCommon(bank, bankManager, funderToAuthorize);
  }

  async fetchTokenAcc(rewardMint: PublicKey, rewardAcc: PublicKey) {
    return this.deserializeTokenAccount(rewardMint, rewardAcc);
  }

  async getRewardAcc(rewardMint: PublicKey) {
    
  }

}
