import React, { FC, useMemo } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    BackpackWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import App from './App';

// Default styles that can be overridden by your app
import './styles/wallet-styles.css';
export const WalletAdapter: FC = () => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.

    const network = WalletAdapterNetwork[process.env.REACT_APP_SOLANA_NETWORK];

    // You can also provide a custom RPC endpoint.
    const endpoint = process.env.REACT_APP_RPC_NODE;

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            //new BackpackWalletAdapter({network}),
            //new PhantomWalletAdapter(),
            new SlopeWalletAdapter(),
            //new SolflareWalletAdapter({ network }),
            new LedgerWalletAdapter(),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <App/>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};