import { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Grid } from '@mui/material';

// EXTERNAL COMPONENTS
import {
  WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

// DEFINED COMPONENTS
import './App.css';
import { COLORS } from './resources/colors';

// IMAGES
import logo from './img/logoheader.png';
import { Connection, PublicKey } from '@solana/web3.js';
import { ASSOCIATED_TOKEN_PROGRAM_ID, Token, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { getNFTsByOwner, INFT } from './app/util/NFTget';
import { GemBankClient } from './common/gem-bank.client';
import { GemBank, initGemBank } from './common/gb';


import { programs } from "@metaplex/js";
import { findVaultPDA, findWhitelistProofPDA } from './common/gem-bank.pda';
import { pause } from './common/util';
import { toBN } from './common/types';

const {
  metadata: { Metadata },
} = programs;

async function fetchWalletTokens(connection : Connection, publicKey: PublicKey){
  const nfts = await getNFTsByOwner(publicKey, connection);
  //console.log(nfts.map(o=>o.mint.toBase58()));
  return nfts.
    map(o => ({
      key: o.mint.toBase58(),
      image: o.externalMetadata.image,
      nft: o
    })).sort(function (a, b) {
      return a.nft.onchainMetadata.data.name.localeCompare(b.nft.onchainMetadata.data.name)
    });
}

async function findATA(mint: PublicKey, owner: PublicKey): Promise < PublicKey > {
  return Token.getAssociatedTokenAddress(
    ASSOCIATED_TOKEN_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    mint,
    owner
  );
}

function App() {

  const wallet = useWallet();
  const connection = useConnection();


  const isWalletConnected = wallet.connected;
  
  const gb = new GemBankClient(connection.connection, wallet.wallet);

  //const count = useAppSelector(selectStaking);
  //const dispatch = useAppDispatch();
  const [unstakedTokens, setUnstakedTokens] = useState([]);
  const [stakedTokens, setStakedTokens] = useState([]);
  const [bank, setBank] = useState<GemBank>();
  const [bankAcc, setBankAcc] = useState<any>();
  const [vault, setVault] = useState(undefined);
  const [numStaked, setNumStaked] = useState<number>(0);
  const [refresh, setRefresh] = useState(Date.now());
  const [rewards, setRewards] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      if (Date.now() >= refresh + 15000) {
        setRefresh(Date.now() - 1);
        refreshVault();
        fetchBankAcc();
      }
      fetchAccruedRewards(Date.now())
    }, 500);
    return () => {
      clearTimeout(timer);
    }
  }, [vault, bankAcc, wallet.connected]);
  
  const refreshVault = async function () {
    if (!wallet.connected) {
      setVault(undefined);
      return;
    };
    let [vaultPda, bump] = await findVaultPDA(new PublicKey(process.env.REACT_APP_BANK_PUBKEY), wallet.publicKey);
    //console.log(vaultPda.toBase58());
    let vaultAcc = null;
    try {
      vaultAcc = await bank.fetchVaultAcc(vaultPda);
      //console.log("FOUND VAULT", vaultAcc);
      setVault(vaultAcc);
    }
    catch (e) {
      //console.log("No vault found for pubkey");
      setVault(null);
    }
  }

  const fetchBank = async function () {
    let gb = await initGemBank(connection, wallet);
    //console.log("FOUND BANK", gb);
    setBank(gb);
  }

  const fetchBankAcc = async function () {
    if (!wallet.connected) {
      return;
    };
    const acc = await bank?.fetchBankAcc(new PublicKey(process.env.REACT_APP_BANK_PUBKEY));
    //console.log("FOUND BANK ACC", acc);
    if(!acc) return;
    setNumStaked(acc.gemCount.toNumber());
    setBankAcc(acc);
  }

  const fetchGdrs = async function () {
    const allVaults = await bank?.fetchAllVaultPDAs(new PublicKey(process.env.REACT_APP_BANK_PUBKEY));
    //console.log(allVaults);
  }

  const fetchAccruedRewards = function (ts: number) {
    if (vault) {
      if (bankAcc) {
        if (vault.refreshHax) {
          setRewards(0); 
          return;
        }
        const tsNow = ts / 1000;
        let lastChange = vault.lastChangeTs.toNumber();
        let numTokensStaked = vault.gemCount.toNumber();
        if (lastChange < 0.1) lastChange = tsNow; 
        const secondsStaked = tsNow - lastChange;
        const accrualRate = bankAcc.accrualRate.toNumber();
        const denominator = bankAcc.denominator.toNumber();
        
        const previouslyAccrued = vault.totalRewardsUnclaimed.toNumber();
        const totalTokensToClaim = previouslyAccrued + numTokensStaked * secondsStaked * accrualRate / denominator;
        const tokensDividedByDecimals = totalTokensToClaim / 10**4;
        setRewards(tokensDividedByDecimals);
      }
    }
  }

  const claim = async function () {
    //console.log("attempting to claim");
    const [vaultAddress, bump] = await findVaultPDA(new PublicKey(process.env.REACT_APP_BANK_PUBKEY), wallet.publicKey);
    const result = await bank.withdrawReward(
      new PublicKey(process.env.REACT_APP_BANK_PUBKEY),
      vaultAddress,
      wallet.publicKey,
      bankAcc.rewardMint,
      wallet.publicKey
    );
    //console.log(result);
    vault.refreshHax = true;
    await pause(2000);
    await refreshVault();
    await fetchBankAcc();
  }

  const initVault = async function () {
    const bankKey = new PublicKey(process.env.REACT_APP_BANK_PUBKEY);
    const res = await bank.initVault(
      bankKey,
      wallet.publicKey,
      wallet.publicKey,
      wallet.publicKey,
      'BlockBox',
    );
    await pause(2000);
    await refreshVault();
    await fetchBankAcc();
  }

  const stakeToken = async function (item) {
    const mint = new PublicKey(item.key);
    const bankKey = new PublicKey(process.env.REACT_APP_BANK_PUBKEY);
    item.nft.txToConfirm = "pending";
    const [vaultAddress, vaultBump] = await findVaultPDA(bankKey, wallet.publicKey);
    const metadata = await Metadata.getPDA(mint);

    const ata = item.nft.pubkey ?? await findATA(mint, wallet.publicKey);

    const rewardata = await findATA(bankAcc.rewardMint, wallet.publicKey);
    //console.log(item);
    const creator = new PublicKey(item.nft.onchainMetadata.data.creators[0].address);
    const [mintProof, bump] = await findWhitelistProofPDA(bankKey, mint);
    const [creatorProof, bump2] = await findWhitelistProofPDA(bankKey, creator);
    try {
      if(bankAcc.stakingFee && bankAcc.stakingFee.toNumber() > 0){
        const res = await bank.depositGemWithFee(
          bankKey,
          vaultAddress,
          wallet.publicKey,
          toBN(1),
          new PublicKey(item.key),
          ata,
          rewardata,
          bankAcc.rewardMint,
          mintProof,
          metadata,
          creatorProof
        );
        item.nft.txToConfirm = res.txSig;
      }
      else{
        const res = await bank.depositGem(
          bankKey,
          vaultAddress,
          wallet.publicKey,
          toBN(1),
          new PublicKey(item.key),
          ata,
          mintProof,
          metadata,
          creatorProof
        );
        item.nft.txToConfirm = res.txSig;
      }
      
    } catch { item.nft.txToConfirm = undefined; }
    
    
    try {
      INFT.confirmTx(item.nft, connection.connection);
    }
    catch{
      item.nft.txToConfirm = undefined;
    }

    await refreshVault();
  }

  const unstakeToken = async function (item) {
    const bankKey = new PublicKey(process.env.REACT_APP_BANK_PUBKEY);
    item.nft.txToConfirm = "pending";
    const [vaultAddress, bump] = await findVaultPDA(new PublicKey(process.env.REACT_APP_BANK_PUBKEY), wallet.publicKey);
    const ata = await findATA(bankAcc.rewardMint, wallet.publicKey);
    try {
      if(bankAcc.bypassLockFee && bankAcc.bypassLockFee.toNumber() > 0){
      const res = await bank.withdrawGemWithFee(
        bankKey,
        vaultAddress,
        wallet.publicKey,
        toBN(1),
        new PublicKey(item.key),
        wallet.publicKey,
        ata,
        bankAcc.rewardMint
        );

        item.nft.txToConfirm = res.txSig;
      }
      else{
        const res = await bank.withdrawGem(
          bankKey,
          vaultAddress,
          wallet.publicKey,
          toBN(1),
          new PublicKey(item.key),
          wallet.publicKey
        );
        item.nft.txToConfirm = res.txSig;
      }
    }
    catch { item.nft.txToConfirm = undefined; } 

    //console.log("confirming transaction");
    try{
    INFT.confirmTx(item.nft, connection.connection);
    //console.log("transaction confirmed", result);
    }
    catch{
      item.nft.txToConfirm = undefined;
    }
    await refreshVault();
  }

  useEffect(() => {
    async function getVault() {
      if (wallet.publicKey) {
        await refreshVault();      
      }
    }
    getVault();
  }, [bank, wallet.connected])
  
  useEffect(() => {
    async function getGemBank() {
      if (wallet.publicKey) {
        await fetchBank();
      }
    }
    getGemBank();
  }, [wallet.connected])

  useEffect(() => {
    async function getGemBankAcc() {
      if (wallet.publicKey) {
        await fetchBankAcc();
      }
    }
    getGemBankAcc();
  }, [bank, wallet.connected]) 


  useEffect(() => {
    async function getUnstakedTokens() {
      if (wallet.publicKey) {
        const tokens = await fetchWalletTokens(connection.connection, wallet.publicKey);
        setUnstakedTokens(tokens);
      }
    }
    getUnstakedTokens();
  }, [wallet.connected, vault])

  useEffect(() => {
    async function getStakedTokens() {
      if (!vault) return;
      const [vaultAddress, bump] = await findVaultPDA(new PublicKey(process.env.REACT_APP_BANK_PUBKEY), wallet.publicKey); 
      
      if (wallet.publicKey) {
        //console.log("finding staked tokens");
        const tokens = await fetchWalletTokens(connection.connection, vault.authority);
        setStakedTokens(tokens);
      }
    }
    getStakedTokens();
  }, [vault])


  return (
    <div className="App">
      <div style={{ width: '100%', justifyContent: 'flex-end', position: 'absolute', textAlign: 'right' }}>
          <WalletMultiButton />
      </div>
      <header className="App-header">
        
        <div className='App-header-content'>
          <img src={logo} className="App-logo" alt="BlockParty Staking" />
          <p style={{ color: '#8269ff', fontSize: '2rem' }}>
            {vault ? `${numStaked} BlockParty Block${numStaked == 1? '' : 's'} staked (${((100*(numStaked/parseInt(process.env.REACT_APP_TOTAL_TOKENS))).toFixed(2))}%)` : 'Raise the stakes!'}
          </p>

         
          {/* <WalletDisconnectButton />*/}
          {/*<ProgressBar
            completed={stakedCount}
            borderRadius="0"
            labelAlignment="outside"
            labelClassName='App-progressBarLabel'
            baseBgColor="#FFFFFF22"
            bgColor={COLORS.yellow}
            width="600"
            labelSize="12px"
            customLabel={`${stakedCount} Gods staked (${(stakedCount / maxCount * 100).toFixed(2)}%)`}
            animateOnRender
            maxCompleted={maxCount}
            className="App-progressBar"
  />*/}
          
            
          {
            (vault !== undefined && isWalletConnected) ?
              <button onClick={() => vault === null ? initVault() : claim() } className='App-normal-button' hidden={true}>{vault === null?'Start Staking':'Claim $BLOCK'}</button>
            : <></>
          }
          {isWalletConnected && vault ? ((rewards) >= 0.00000 ? <p>{`${rewards.toFixed(4)} $BLOCK to claim`}</p> : <></>):<></> }
        </div>
        {isWalletConnected && vault ?
          <Tabs>
            <TabList style={{height: '80px'}}>
              <Tab>Unstaked</Tab>
              <Tab>Staked</Tab>
            </TabList>
            <TabPanel style={{
            textAlign: 'center'
            }}>
              <Grid container spacing={{ xs: 3, md: 4 }} style={{ justifyContent: 'center', maxWidth: '90vw' }}
              >
                {unstakedTokens.filter(o=>!o.nft.txToConfirm || o.nft?.txToConfirm === "pending").map((item) => (
                  <Grid item key={item.key} style={
                    {
                      borderRadius: 15,
                      border: `1px solid ${COLORS.yellow}`,
                      background: COLORS.gray,
                      padding: '6px',
                      maxWidth: '250px',
                      minWidth: '200px',
                      maxHeight: '60%',
                      margin: '3px'
                    }} >
                    <img style={{ borderRadius: 9, maxWidth: '100%' }}
                      src={`${item.image}`}
                      title={`${item.nft.externalMetadata.name}`}
                      loading="lazy"
                    />
                    <div style={{ fontSize: '20px', textAlign: 'left', lineHeight: '13px' }}>
                      <h5 style={{ lineHeight: '0px', margin: '20px', textAlign: 'center' }}>{item.nft.externalMetadata.name}</h5>
                      <button disabled={item.nft.txToConfirm? true:false} onClick={()=>stakeToken(item)} className='App-god-button'><p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', padding: '0px', margin: '0px' }}>{item.nft.txToConfirm? 'Staking...' : 'Stake'}</p></button>
                    </div>
                  </Grid>
                ))}
              </Grid>


            </TabPanel>
            <TabPanel style={{
            textAlign: 'center'
            }}>
              <Grid container spacing={{xs:3, md: 4}} style={{justifyContent: 'center', maxWidth: '90vw'}}
              >
                {stakedTokens.filter(o=>!o.nft.txToConfirm || o.nft?.txToConfirm === "pending").map((item) => (
                  <Grid item key={item.key} style={
                    {
                      borderRadius: 15,
                      border: `1px solid ${COLORS.yellow}`,
                      background: COLORS.gray,
                      padding: '6px',
                      maxWidth: '250px',
                      minWidth: '200px',
                      maxHeight: '60%',
                      margin: '3px'
                    }} >
                    <img style={{ borderRadius: 9, maxWidth: '100%'}}
                      src={`${item.image}`}
                      title={`${item.nft.externalMetadata.name}`}
                      loading="lazy"
                      alt=''
                    />
                    <div style={{ fontSize: '20px', textAlign: 'left', lineHeight: '13px' }}>
                      <h5 style={{ lineHeight: '0px', margin: '20px', textAlign: 'center' }}>{item.nft.externalMetadata.name}</h5>
                      <button disabled={item.nft.txToConfirm? true:false} onClick={()=>unstakeToken(item)} className='App-god-button'><p style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', padding: '0px', margin: '0px' }}>{item.nft.txToConfirm? 'Unstaking...' : 'Unstake'}</p></button>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          </Tabs>
          : <p>Please connect your wallet</p>}
      </header>
    </div>
  );
}

export default App;
